import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/Image';
import Heading from 'components/Heading';

import classes from './ModuleItem.scss';

function ModuleItem({ title, image, children }) {
  return (
    <div className={classes.root}>
      <Image className={classes.image} image={image} alt={title} />

      <div className={classes.content}>
        <Heading size="sm" color="primary-light">
          {title}
        </Heading>

        {children}
      </div>
    </div>
  );
}

ModuleItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node.isRequired,
};

export default ModuleItem;
