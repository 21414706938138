import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import modulesImage1 from 'assets/images/pages/ecommerce_b2bmodules/1.jpg';

import Title from 'blocks/Title';
import Content from 'blocks/Content';
import Grid from 'blocks/Grid';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Button from 'components/Button';
import ModuleItem from 'components/ModuleItem';

const B2bEcommerce = ({ data }) => {
  const items = data.allModules.nodes;

  return (
    <Layout>
      <Seo image={{ src: modulesImage1 }} />

      <Title
        image={{ src: modulesImage1 }}
        alt="Geweldige B2B modules"
        subtitle="B2B oplossingen die wereldwijd worden ingezet"
        title="Geweldige B2B modules"
      >
        Wij zetten onze E-commerce kennis op meerdere vlakken en zo breed
        mogelijk in. Met Cart2Quote maken wij geweldige B2B modules die
        wereldwijd ingezet worden door talloze E-commerce platforms. Daar zijn
        we trots op.
      </Title>

      <Section>
        <Content>
          <Heading size="xl" as="h2">
            Geen standaard ‘Magento boer’
          </Heading>

          <Text spacing="lg">
            Cart2Quote is geen ‘Magento-extensie-boer’ zoals zovelen en biedt
            geen tientallen of honderden oppervlakkige, simpele extensies. Wij
            zaaien gericht, om kwaliteit te kunnen oogsten. Wij bouwen en
            onderhouden vier extreem goede, kwalitatief hoogwaardige modules die
            een must zijn voor ieder B2B E-commerce platform. Bekijk hieronder
            een overzicht van onze modules!
          </Text>
        </Content>
      </Section>

      <Section color="secondary-pastel" shorten>
        <Content>
          <Heading size="xl" as="h2">
            Onze Magento B2B modules
          </Heading>
        </Content>

        <Grid width={2}>
          {items.map((item) => (
            <ModuleItem key={item._id} title={item.name} image={item.logo}>
              <Text spacing="md">{item.description}</Text>

              <Button
                color="primary-light"
                outline
                to={`/ecommerce/b2b-modules/${item.slug}`}
                hoverColor="tertiary"
              >
                Lees verder
              </Button>
            </ModuleItem>
          ))}
        </Grid>
      </Section>

      <ContactCta />
    </Layout>
  );
};

B2bEcommerce.propTypes = {
  data: PropTypes.shape({
    allModules: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          logo: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query modules {
    allModules {
      nodes {
        _id
        slug
        name
        description
        logo {
          sharpImage: childImageSharp {
            fixed(width: 300, height: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default B2bEcommerce;
